<template>
    <header>
        <div class="desktop-header">
            <router-link to="/" class="nav-logo">
                <img src="@/assets/logo.webp" alt="Logo" loading="lazy" />
                <h5 class="logo-name">{{ title }}</h5>
            </router-link>
            <nav class="nav-bar">
                <ul class="nav-list">
                    <li class="nav-item">
                        <router-link to="/articles" class="nav-link">Articles</router-link>
                    </li>
                    <li class="nav-item">
                        <router-link to="/videos" class="nav-link">Videos</router-link>
                    </li>
                    <li class="nav-item">
                        <a href="https://www.youtube.com/@TheVideoGameVault" target="_blank" class="btn-secondary">
                            <i class="fa fa-youtube" aria-hidden="true"></i>
                            Youtube
                        </a>
                    </li>
                </ul>
            </nav>
        </div>

        <div class="mobile-header">
            <router-link to="/" class="nav-logo">
                <img src="@/assets/logo.webp" alt="Logo" loading="lazy" />
                <h5 class="logo-name">{{ title }}</h5>
            </router-link>

            <div class="nav-toggle" @click="menuClick" :class="{ change: isNavActive }">
                <div class="bar bar-one"></div>
                <div class="bar bar-two"></div>
                <div class="bar bar-three"></div>
            </div>

            <nav class="nav-bar" id="navbar" :class="{ 'nav-open': isNavActive }">
                <ul class="nav-list">
                    <li class="nav-item" @click="menuClick">
                        <router-link to="/articles" class="nav-link">Articles</router-link>
                    </li>

                    <li class="nav-item" @click="menuClick">
                        <router-link to="/videos" class="nav-link">Videos</router-link>
                    </li>

                    <li class="nav-item" @click="menuClick">
                        <a class="nav-link" :href="'mailto:' + email + '?subject=Contact Form - TheVideoGameVault.com'">
                            {{ email }}
                        </a>
                    </li>
                </ul>

                <a href="https://www.youtube.com/@TheVideoGameVault" class="nav-list youtube-button btn-secondary"
                    target="_blank">
                    <i class="fa fa-youtube" aria-hidden="true"></i> Youtube
                </a>
            </nav>
        </div>
    </header>
</template>

<script setup lang="ts">
import { ref } from "vue";

const title = "The Video Game Vault";
const email = "info@thevideogamevault.com"
const isNavActive = ref<boolean>(false)

const menuClick = () => {
    isNavActive.value = !isNavActive.value
}
</script>