<template>
    <main class="root">
        <div class="index-sections">
            <div class="section-header">
                <h2 class="section-title">
                    Full game
                    <span class="gradient-text"> Playlists</span>
                </h2>
            </div>

            <div class="video-section">
                <playlist-component v-for="video in videoData" :key="video.id" :title="video.title"
                    :details="video.details" :desc="video.description" :thumbnailsrc="video.thumbnail"
                    :playlisturl="video.playlistUrl" />
            </div>
        </div>
    </main>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { useMainStore } from "../stores/mainStore";
import PlaylistComponent from "./PlaylistComponent.vue";

export default defineComponent({
    name: "PlaylistsPage",
    components: {
        PlaylistComponent,
    },
    props: {},
    data() {
        return {
            videoData: useMainStore().videoData,
        };
    },
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
../stores/mainStore