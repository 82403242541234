<template>
  <article class="index-sections">
    <router-link to="/articles" class="nav-link">
      <i class="fa fa-arrow-circle-o-left" aria-hidden="true"></i>
      Back to the list of articles
    </router-link>
    <div class="article-header">
      <h2 class="article-heading">{{ articleData?.title }}</h2>
      <small class="article-subheading">Published: {{ articleData?.published }}</small>
    </div>
    <div class="article-body">
      <div class="article-img">
        <img :src="thumbnailUrl" alt="Featured Article Thumbnail" loading="lazy">
      </div>
      <div class="article-writeup">
        <p class="article-title">{{ articleData?.subTitle }}</p>
        <p v-html="articleData?.body" class="article-description"></p>
      </div>
    </div>
  </article>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { useMainStore } from "../stores/mainStore";

export default defineComponent({
  name: "ArticlePage",
  props: {
    tests: { type: String }
  },
  data() {
    return {
      articleData: useMainStore().articleData.find((x) => x.url == this.$route.params.articlename),
    };
  },
  computed: {
    thumbnailUrl() : string {
      return require('@/assets/articles/' + this.articleData?.img);
    },
  }
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
