<template>
    <div class="index-sections">
        <router-link to="/videos" class="nav-link">
            <i class="fa fa-arrow-circle-o-left" aria-hidden="true"></i>
            Back to the list of video playlists
        </router-link>
        <div class="section-header">
            <h2 class="section-title">{{ videoData?.title }}
                <span class="gradient-text"> Playlist</span>
            </h2>
            <h4>{{ videoData?.items.length }} total videos.</h4>
            <a :href="'https://youtube.com/playlist?list=' + videoData?.id" target="_blank" class="section-link">
                <p class="view-link">View playlist on YouTube
                    <i class="fa fa-arrow-circle-o-right" aria-hidden="true"></i>
                </p>
            </a>
        </div>

        <div class="video-section">
            <video-component v-for="video in videoData?.items" :key="video.id" :title="video.title"
                :youtubeid="video.id" />
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { useMainStore } from "../stores/mainStore";
import VideoComponent from "./VideoComponent.vue";

export default defineComponent({
    name: "VideosPage",
    components: {
        VideoComponent,
    },
    data() {
        return {
            videoData: useMainStore().videoData.find(
                (x) => x.playlistUrl == this.$route.params.videoname
            ),
        };
    },
});
</script>