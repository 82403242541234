<template>
    <div class="item">
        <div class="title-img">
            <a :href="'videos/' + playlisturl">
                <img :src="thumbnailsrc" />
            </a>
        </div>
        <div class="content">
            <div class="title">{{ title }}</div>
            <div class="details">{{ details }}</div>
            <div class="desc">{{ desc }}</div>

            <a :href="'videos/' + playlisturl" class="gradient-text">
                View Playlist</a>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
    name: "PlaylistComponent",
    props: {
        title: { type: String },
        details: { type: String },
        desc: { type: String },
        playlisturl: { type: String },
        thumbnailsrc: { type: String },
    },
    computed: {
        thumbnailUrl(): string {
            return `@/assets/playlists/${this.thumbnailsrc}`;
        },
    },
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
