import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "video" }
const _hoisted_2 = {
  key: 0,
  class: "flex-item"
}
const _hoisted_3 = { class: "flex-img" }
const _hoisted_4 = { class: "video-title" }
const _hoisted_5 = {
  key: 1,
  class: "item"
}
const _hoisted_6 = { class: "title-img" }
const _hoisted_7 = { class: "content" }
const _hoisted_8 = { class: "title" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_YouTubePlayerComponent = _resolveComponent("YouTubePlayerComponent")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.small)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_YouTubePlayerComponent, {
              youtubeid: _ctx.youtubeid,
              title: _ctx.title
            }, null, 8, ["youtubeid", "title"])
          ]),
          _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.title), 1)
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_5, [
          _createElementVNode("div", _hoisted_6, [
            _createVNode(_component_YouTubePlayerComponent, {
              youtubeid: _ctx.youtubeid,
              title: _ctx.title
            }, null, 8, ["youtubeid", "title"])
          ]),
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.title), 1)
          ])
        ]))
  ]))
}