<template>
  <section class="index-sections">
    <div class="section-header">
      <h2 class="section-title">Latest
        <span class="gradient-text"> videos</span>
      </h2>
      <a href="videos" class="section-link">
        <p class="view-link">View all videos
          <i class="fa fa-arrow-circle-o-right" aria-hidden="true"></i>
        </p>
      </a>
    </div>
    <div class="flex-wrapper">
      <VideoComponent v-for="video in videoData?.slice(0, videoCount)" :key="video.id" :title="video.title"
        :youtubeid="video.id" :small=true />
    </div>
  </section>

  <section class="index-sections">
    <div class="section-header">
      <h2 class="section-title">Latest
        <span class="gradient-text"> articles</span>
      </h2>
      <a href="articles" class="section-link">
        <p class="view-link">View all articles
          <i class="fa fa-arrow-circle-o-right" aria-hidden="true"></i>
        </p>
      </a>
    </div>
    <div class="flex-wrapper">
      <ArticleComponent v-for="article in articleData.slice(0, articleCount)" :key="article.title" :title="article.title"
        :img=article.img :articleurl=article.url :desc=article.subTitle :small=true />
    </div>
  </section>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { useMainStore } from "../stores/mainStore";
import VideoComponent from "./VideoComponent.vue";
import ArticleComponent from "./ArticleComponent.vue"

export default defineComponent({
  name: "HomePage",
  components: {
    VideoComponent,
    ArticleComponent
  },
  data() {
    return {
      videoData: useMainStore().videoData.map(x => x.items.reverse()).flat(),
      videoCount: 6,
      articleData: useMainStore().articleData,
      articleCount: 6
    };
  },
});
</script>