import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "article" }
const _hoisted_2 = {
  key: 0,
  class: "flex-item"
}
const _hoisted_3 = { class: "flex-img" }
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "article-texts" }
const _hoisted_6 = { class: "article-title" }
const _hoisted_7 = { class: "article-description" }
const _hoisted_8 = ["href"]
const _hoisted_9 = {
  key: 1,
  class: "item"
}
const _hoisted_10 = { class: "title-img" }
const _hoisted_11 = ["src"]
const _hoisted_12 = { class: "content" }
const _hoisted_13 = { class: "title" }
const _hoisted_14 = { class: "details" }
const _hoisted_15 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.small)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("img", {
              src: _ctx.thumbnailUrl,
              alt: "Featured Article Thumbnail",
              loading: "lazy"
            }, null, 8, _hoisted_4)
          ]),
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("p", _hoisted_6, _toDisplayString(_ctx.title), 1),
            _createElementVNode("p", _hoisted_7, _toDisplayString(_ctx.subTitle), 1),
            _createElementVNode("a", {
              href: '/articles/' + _ctx.articleurl,
              class: "gradient-text"
            }, "Read the full article here", 8, _hoisted_8)
          ])
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_9, [
          _createElementVNode("div", _hoisted_10, [
            _createElementVNode("img", {
              src: _ctx.thumbnailUrl,
              alt: "Featured Article Thumbnail",
              loading: "lazy"
            }, null, 8, _hoisted_11)
          ]),
          _createElementVNode("div", _hoisted_12, [
            _createElementVNode("div", _hoisted_13, _toDisplayString(_ctx.title), 1),
            _createElementVNode("div", _hoisted_14, _toDisplayString(_ctx.subTitle), 1),
            _createElementVNode("a", {
              href: '/articles/' + _ctx.articleurl,
              class: "gradient-text"
            }, "Read the full article here", 8, _hoisted_15)
          ])
        ]))
  ]))
}