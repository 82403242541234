import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "item" }
const _hoisted_2 = { class: "title-img" }
const _hoisted_3 = ["href"]
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "content" }
const _hoisted_6 = { class: "title" }
const _hoisted_7 = { class: "details" }
const _hoisted_8 = { class: "desc" }
const _hoisted_9 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("a", {
        href: 'videos/' + _ctx.playlisturl
      }, [
        _createElementVNode("img", { src: _ctx.thumbnailsrc }, null, 8, _hoisted_4)
      ], 8, _hoisted_3)
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.title), 1),
      _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.details), 1),
      _createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.desc), 1),
      _createElementVNode("a", {
        href: 'videos/' + _ctx.playlisturl,
        class: "gradient-text"
      }, " View Playlist", 8, _hoisted_9)
    ])
  ]))
}