<template>
  <div class="video-section">
    <div class="index-sections">

      <div class="section-header">
        <h2 class="section-title">Articles
          <span class="gradient-text"> list</span>
        </h2>
      </div>

      <div class="articles-section">
        <ArticleComponent v-for="article in articleData" :key="article.title" :title="article.title" :img=article.img
          :articleurl=article.url :subTitle=article.subTitle />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { useMainStore } from "../stores/mainStore";
import ArticleComponent from "./ArticleComponent.vue";

export default defineComponent({
  name: "ArticlesListPage",
  components: {
    ArticleComponent
  },
  data() {
    return {
      articleData: useMainStore().articleData,
    };
  }
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
