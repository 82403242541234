<template>
    <iframe :src="'https://www.youtube.com/embed/' + youtubeid" :title=title frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowfullscreen loading="lazy"></iframe>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
    name: "YouTubePlayerComponent",
    props: {
        title: { type: String },
        youtubeid: { type: String },
    }
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
