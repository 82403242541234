<template>
    <section class="index-sections index-sections-banner">
      <h1>404 Page Not Found</h1>
  </section>
  
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "PageNotFoundPage",
  props: {
    msg: String,
  },
});
</script>
