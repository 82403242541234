import { createRouter, createWebHistory } from 'vue-router'
import { useMainStore } from "../stores/mainStore";

import HomePage from '../components/HomePage.vue'
import ArticlesListPage from '../components/ArticlesListPage.vue'
import ArticlePage from '../components/ArticlePage.vue'
import PlaylistsPage from '../components/PlaylistsPage.vue'
import VideosPage from '../components/VideosPage.vue'
import PageNotFoundPage from '../components/PageNotFoundPage.vue'

const routes = [
    {
        path: '/',
        name: 'homepage',
        component: HomePage
    },
    {
        path: '/articles',
        name: 'articlesListPage',
        component: ArticlesListPage
    },
    {
        path: '/articles/:articlename',
        name: 'articlespage',
        component: ArticlePage,
        beforeEnter: (to: any, from: any, next: any) => {

            const mainStore = useMainStore();
            const article = mainStore.articleData.find((x: any) => x.url == to.params.articlename);

            if (article == null) {
                next({ name: 'pageNotFound' });
                return;
            }
            next();
        }
    },
    {
        path: '/videos',
        name: 'PlaylistListPage',
        component: PlaylistsPage
    },
    {
        path: '/videos/:videoname',
        name: 'playlistPage',
        component: VideosPage,
        beforeEnter: (to: any, from: any, next: any) => {

            const mainStore = useMainStore();
            const video = mainStore.videoData.find((x) => x.playlistUrl == to.params.videoname);

            if (video == null) {
                next({ name: 'pageNotFound' });
                return;
            }

            next();
        }
    },
    {
        path: '/:catchAll(.*)*',
        name: 'pageNotFound',
        component: PageNotFoundPage
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router