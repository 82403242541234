<template>
  <div class="article">
    <div class="flex-item" v-if=small>
      <div class="flex-img">
        <img :src="thumbnailUrl" alt="Featured Article Thumbnail" loading="lazy">
      </div>
      <div class="article-texts">
        <p class="article-title">{{ title }}</p>
        <p class="article-description">{{ subTitle }}</p>
        <a :href="'/articles/' + articleurl" class="gradient-text">Read the full article here</a>
      </div>
    </div>

    <div class="item" v-else>
      <div class="title-img">
        <img :src="thumbnailUrl" alt="Featured Article Thumbnail" loading="lazy">
      </div>
      <div class="content">
        <div class="title">{{ title }}</div>
        <div class="details">{{ subTitle }}</div>
        <a :href="'/articles/' + articleurl" class="gradient-text">Read the full article here</a>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "ArticleComponent",
  props: {
    img: { type: String },
    title: { type: String },
    subTitle: { type: String },
    articleurl: { type: String },
    small: { type: Boolean }
  },
  computed: {
    thumbnailUrl() : string {
      return require(`@/assets/articles/${this.img}`);
    },
  }
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
