import { ref } from 'vue';
import { defineStore } from 'pinia'
import { Playlist } from "../models/Playlist";
import { Article } from "../models/Article";
import videoJsonData from '../data/videos.json'
import articleJsonData from '../data/articles.json'

export const useMainStore = defineStore("mainStore", () => {
    const videoData = ref<Array<Playlist>>(videoJsonData);
    const articleData = ref<Array<Article>>(articleJsonData);

    return {
        videoData,
        articleData
    }
})