<template>
  <div class="video">
    <div class="flex-item" v-if="small">
      <div class="flex-img">
        <YouTubePlayerComponent :youtubeid="youtubeid" :title="title" />
      </div>
      <p class="video-title">{{ title }}</p>
    </div>

    <div class="item" v-else>
      <div class="title-img">
        <YouTubePlayerComponent :youtubeid="youtubeid" :title="title" />
      </div>
      <div class="content">
        <div class="title">{{ title }}</div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import YouTubePlayerComponent from "./YouTubePlayerComponent.vue"

export default defineComponent({
  name: "VideoComponent",
  components: {
    YouTubePlayerComponent
  },
  props: {
    title: { type: String },
    youtubeid: { type: String },
    small: { type: Boolean }
  }
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
